import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import constants from "../constants";

const httpMediaLink = createUploadLink({
  uri: constants.mediaGqlUrl,
});

const httpGatewayLink = createUploadLink({
  uri: constants.gatewayGqlUrl,
});

const httpResellerLink = createUploadLink({
  uri: constants.resellerGqlUrl,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = JSON.parse(localStorage.getItem("accessToken"))?.tokenString;
    return {
      headers: {
        ...headers,
        "service-code":
          headers?.service === constants.mySabayServiceCode
            ? constants.mySabayServiceCode
            : constants.serviceCode,
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    console.log("error:", error);
    return {
      headers: {
        ...headers,
        "service-code":
          headers?.service === constants.mySabayServiceCode
            ? constants.mySabayServiceCode
            : constants.serviceCode,
      },
    };
  }
});

const client = new ApolloClient({
  // connectToDevTools: true,
  link: ApolloLink.split(
    (operation) =>
      operation.getContext().clientName === "media-service" ||
      operation.getContext().clientName === "soyonow-service",
    authLink.concat(httpMediaLink), // apollo will send to this if clientName is "media-service"
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "reseller-service",
      authLink.concat(httpResellerLink), // apollo will send to this if clientName is "reseller-service"
      authLink.concat(httpGatewayLink) //  otherwise will send to this
    )
  ),
  addTypename: false,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      // fetchPolicy: 'network-only',
      errorPolicy: "all",
    },
  },
});

export default client;
