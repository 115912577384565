/* eslint-disable */
import Promise from "promise";
import c from "../constants";
import Axios from "axios";

const { default: PQueue } = require("p-queue");
const queue = new PQueue({ concurrency: 1 });
export const getToken = async () => {
  return new Promise((resolve, reject) => {
    queue
      .add(checkToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkToken = () => {
  return new Promise((resolve, reject) => {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    const isLogin = JSON.parse(localStorage.getItem("isLogin"));
    const checkingProfile = JSON.parse(localStorage.getItem("checkingProfile"));
    if (isLogin) {
      if (accessToken) {
        ssoVerifyToken(accessToken?.tokenString)
          .then((responseAccesstoken) => {
            if (responseAccesstoken.status === 1) {
              resolve({
                status: 1,
                accessToken: accessToken?.tokenString,
              });
            } else {
              const refreshToken = JSON.parse(
                localStorage.getItem("refreshToken")
              );
              ssoRefreshToken(refreshToken?.tokenString)
                .then((responseRefreshToken) => {
                  if (responseRefreshToken?.status === 1) {
                    // success get new access token
                    resolve({
                      status: 1,
                      accessToken: responseRefreshToken.accessToken,
                    });
                  } else {
                    alert("Your session has expired. Please log in.");
                    localStorage.clear();
                    window.location.reload(false);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        alert("Your session has expired. Please log in.");
        localStorage.clear();
        window.location.reload(false);
      }
    } else if (checkingProfile) {
      resolve({
        status: 1,
        accessToken: accessToken?.tokenString,
      });
    } else {
      reject(
        reject({
          status: 0,
          message: "No token",
        })
      );
    }
  });
};

export const ssoVerifyToken = (accessToken) => {
  return new Promise((resolve, reject) => {
    const headers = { "service-code": c.serviceCode };
    const query = `
      query{
        sso_verifyToken(accessToken:"${accessToken}"){
          uuid
          serviceCode
          mysabayUserID
        }
      }
    `;
    gqlRequest(query, headers)
      .then((response) => {
        if (response?.status === "success") {
          resolve({
            status: 1,
            accessToken,
          });
        }
        if (response?.status === "error") {
          resolve({ status: 0 });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ssoRefreshToken = (refreshToken) => {
  return new Promise((resolve, reject) => {
    const headers = { "service-code": c.serviceCode };
    const query = `
      mutation {
        sso_refreshToken(refreshToken:"${refreshToken}"){
          accessToken
          refreshToken
          expire
        }
      }
    `;
    gqlRequest(query, headers)
      .then((response) => {
        if (response?.status === "success") {
          if (response?.data?.sso_refreshToken) {
            if (
              response?.data?.sso_refreshToken?.accessToken &&
              response?.data?.sso_refreshToken.expire
            ) {
              localStorage.setItem(
                "accessToken",
                JSON.stringify({
                  tokenString: response?.data?.sso_refreshToken.accessToken,
                  expire: response?.data?.sso_refreshToken.expire,
                })
              );
            }
            if (response?.data?.sso_refreshToken?.refreshToken) {
              localStorage.setItem(
                "refreshToken",
                JSON.stringify({
                  tokenString: response?.data?.sso_refreshToken?.refreshToken,
                })
              );
            }
            resolve({
              accessToken: response?.data?.sso_refreshToken?.accessToken,
              status: 1,
            });
          }
        }
        if (response?.status === "error") {
          resolve({ status: 0 });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const gqlRequest = (query = ``, headers = h) => {
  return new Promise((resolve, reject) => {
    Axios.post(c.gatewayGqlUrl, { query }, { headers })
      .then((response) => {
        if (response?.data?.errors) {
          resolve({
            status: "error",
            message: response?.data?.errors[0]?.message,
          });
        } else {
          resolve({ status: "success", data: response?.data?.data });
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors[0]?.message) {
          reject({ message: error?.response?.data?.errors[0]?.message });
        } else {
          reject({ message: `We're sorry, but something went wrong.` });
        }
      });
  });
};
