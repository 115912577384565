import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import apolloClient from "./providers/apolloClient";
import { ApolloProvider } from "@apollo/react-hooks";
import { CFade } from "@coreui/react";
// container
import NoneLoginRoutes from "./routes/noneLoginRoutes";
import { getToken } from "./providers/network";
import ProtectedRoute from "./routes/protectedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  useEffect(() => {
    if (window?.location?.hash === "#/") {
      const isLogin = localStorage.getItem("isLogin");
      if (isLogin) {
        getToken();
      }
    }
  }, []);

  return (
    <HashRouter>
      <ApolloProvider client={apolloClient}>
        <React.Suspense fallback={loading}>
          <Switch>
            {NoneLoginRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            <ProtectedRoute
              path="/"
              name="Home"
            />
          </Switch>
        </React.Suspense>
      </ApolloProvider>
    </HashRouter>
  );
};

export default App;
