import React from "react";

const Dashboard = React.lazy(() => import("../views/dashboard/dashboard"));
const SettingProfile = React.lazy(() =>
  import("../views/settings/profile/profile")
);
const SettingGeneral = React.lazy(() =>
  import("../views/settings/general/general")
);
const SettingKey = React.lazy(() => import("../views/settings/keys/keys"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/settings-profile",
    exact: true,
    name: "Settings Profile",
    title: "Settings Profile",
    component: SettingProfile,
  },
  {
    path: "/settings-general",
    exact: true,
    name: "Settings General",
    title: "Settings General",
    component: SettingGeneral,
  },
  {
    path: "/settings-key",
    exact: true,
    name: "Setting Key",
    title: "Setting Key",
    component: SettingKey,
  },
];

export default routes;
