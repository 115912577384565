import React from "react";
import LoginRoutes from "../../routes/loginRoutes";
import { Switch, Route } from "react-router-dom";
import Loading from "../loading";
import { CFade } from "@coreui/react";
const LoginContainer = React.lazy(() => import("./loginContainer"));
const AOGContainer = React.lazy(() => import("./aog/aog"));
const FinanceContainer = React.lazy(() => import("./finance/finance"));
const UserContainer = React.lazy(() => import("./user/user"));
const MediaContainer = React.lazy(() => import("./media/media"));
const NotificationContainer = React.lazy(() =>
  import("./notification/notification")
);
const ServicesContainer = React.lazy(() => import("./services/services"));
const StoreContainer = React.lazy(() => import("./store/store"));
const SoyonowContainer = React.lazy(() => import("./soyonow/soyonow"));
const EnovelContainer = React.lazy(() => import("./enovel/enovel"));
const AdsContainer = React.lazy(() => import("./ads/ads"));
const MicroservicesContainer = React.lazy(() =>
  import("./microservices/microservices")
);
const PaymentOptionsContainer = React.lazy(() =>
  import("./paymentoptions/paymentoptions")
);
const ResellersContainer = React.lazy(() => import("./resellers/resellers"));
const MySabayContainer = React.lazy(() => import("./mysabay/mySabay"));
const LogsContainer = React.lazy(() => import("./logs/logs"));
const CashierContainer = React.lazy(() => import("./cashier/cashier"));
const GameFiContainer = React.lazy(()=> import('./gameFi/gameFi'))

const Container = () => (
  <LoginContainer>
    <React.Suspense fallback={Loading}>
      <Switch>
        {LoginRoutes.map((route, idx) => {
          return (
            route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => (
                  <CFade>
                    <route.component {...props} />
                  </CFade>
                )}
              />
            )
          );
        })}
        <Route
          path="/aog"
          name="AOG"
          render={(props) => <AOGContainer {...props} />}
        />
        <Route
          path="/finance"
          name="Finance"
          render={(props) => <FinanceContainer {...props} />}
        />
        <Route
          path="/users"
          name="Users"
          render={(props) => <UserContainer {...props} />}
        />
        <Route
          path="/media"
          name="Media"
          render={(props) => <MediaContainer {...props} />}
        />
        <Route
          path="/notification"
          name="Notification"
          render={(props) => <NotificationContainer {...props} />}
        />
        <Route
          path="/services"
          name="Services"
          render={(props) => <ServicesContainer {...props} />}
        />
        <Route
          path="/store"
          name="Stores"
          render={(props) => <StoreContainer {...props} />}
        />
        <Route
          path="/soyonow"
          name="Soyonow"
          render={(props) => <SoyonowContainer {...props} />}
        />
        <Route
          path="/soyonow/dashboard"
          name="Soyonow Dashboard"
          render={(props) => <SoyonowContainer {...props} />}
        />
        <Route
          path="/enovel"
          name="Enovel"
          render={(props) => <EnovelContainer {...props} />}
        />
        <Route
          path="/ads"
          name="Ads"
          render={(props) => <AdsContainer {...props} />}
        />
        <Route
          path="/microservices"
          name="Microservices"
          render={(props) => <MicroservicesContainer {...props} />}
        />
        <Route
          path="/resellers"
          name="Resellers"
          render={(props) => <ResellersContainer {...props} />}
        />

        <Route
          path="/paymentoptions"
          name="PaymentOptions"
          render={(props) => <PaymentOptionsContainer {...props} />}
        />

        <Route
          path="/mysabay"
          name="MySabay"
          render={(props) => <MySabayContainer {...props} />}
        />
        <Route
          path="/logs"
          name="Logs"
          render={(props) => <LogsContainer {...props} />}
        />
        <Route
          path="/cashier"
          name="Cashier"
          render={(props) => <CashierContainer {...props} />}
        />
        <Route
          path="/gamefi"
          name="GameFi"
          render={(props) => <GameFiContainer {...props}/>}
        />
      </Switch>
    </React.Suspense>
  </LoginContainer>
);

export default Container;
