import ShareIcon from "./assets/media/share_icon.png";

const env = window.RUNTIME_NODE_ENV ? window.RUNTIME_NODE_ENV : "development";

const appSC = window.RUNTIME_APP_SECRET
  ? window.RUNTIME_APP_SECRET
  : "efsLSLuEGiXcAZZmlwJbgsbzDC2JdKgR";

const gatewayGqlUrl = window.RUNTIME_GATEWAY_GQL_URL
  ? window.RUNTIME_GATEWAY_GQL_URL
  : "https://admin-gateway.master.sabay.com/graphql";

const mediaGqlUrl = window.RUNTIME_MEDIA_GQL_URL
  ? window.RUNTIME_MEDIA_GQL_URL
  : "http://media-api.master.sabay.com/graphql";

const resellerGqlUrl = window.RUNTIME_RESELLER_GQL_URL
  ? window.RUNTIME_RESELLER_GQL_URL
  : "https://reseller-api.master.mysabay.com/graphql";

const serviceCode = window.RUNTIME_SERVICE_CODE
  ? window.RUNTIME_SERVICE_CODE
  : "admin_dash";

const soyonowVideoFolderId = window.RUNTIME_SOYONOW_PARENT_VIDEO_FOLDER_ID
  ? window.RUNTIME_SOYONOW_PARENT_VIDEO_FOLDER_ID
  : "609b5c8985f60a00304fcd8e";

const adminCashierUrl = window.RUNTIME_ADMIN_CASHIER_URL
  ? window.RUNTIME_ADMIN_CASHIER_URL
  : "https://admin-cashier.master.mysabay.com/v1/charge/auth/";

const transferSGDomainName = window.RUNTIME_TRANSFER_SG_DOMAIN_NAME
  ? window.RUNTIME_TRANSFER_SG_DOMAIN_NAME
  : "user.master.mysabay.com";

const MEDIA_GROUP = {
  VIDEO: 1,
  IMAGE: 2,
  AUDIO: 3,
  OTHER: 4,
};

const shareService = { name: "Shares", code: "shares", logo: ShareIcon };

const elementType = {
  INPUT: "input",
  SELECT: "select",
  MULTIPLE_SELECT: "multipleSelect",
  CHECKBOX: "checkbox",
  CHECKBOX_GROUP: "checkboxGroup",
  BOOLEAN_CHECKBOX: "booleanCheckbox",
  RADIO: "radio",
  TEXTAREA: "textarea",
  DATE_PICKER: "datepicker",
  PAYMENT_SERVICE_PROVIDER: "paymentServiceProvider",
  GIFT_CARD: "giftCard",
  COMBINE_CONTROL: "combineControl",
};

const videoSizeLimit = 2000000000; // 2 gigabytes size as bytes

const MEDIA_TYPE = {
  MOVIE: 1,
  SERIES: 2,
};

const JX2_SERVICE_CODE = "jx2";
const VIP_BUNDLE_CATEGORY_TITLE = "VIP Bundle";
const GAME_BUNDLE_ATTACHED_ITEM_FIELD_NAME = "attachedItems";
const mySabayServiceCode = "mysabay_user";

const VIP_LEVEL_MANDATORY_FIELD_NAME = "vipLevelMandatory";
const VIP_LEVEL_MANDATORY_OPTIONS = [
  { id: "0", name: "Bronze" },
  { id: "1", name: "Silver" },
  { id: "2", name: "Gold" },
];

const VIP_LEVEL_MANDATORY_OPTIONS_FOR_UPDATE = [
  { value: "0", label: "Bronze" },
  { value: "1", label: "Silver" },
  { value: "2", label: "Gold" },
];

const Status = {
  block: "Block",
  int: 0,
};

const Types = {
  MysabayUserId: "MysabayUserId",
  GameAccount: "GameAccount",
};

const gameTypes = {
  JX2: "jx2",
  AK2: "ak2",
};

const gameFiPlatforms = {
  WEB: "Web",
  ANDROID: "Android",
  IOS: "iOS",
  PC: "PC",
};

const toggleBlockchain = {
  Disabled: "Disabled",
  Enabled: "Enabled",
};

const metadataType = {
  DEVELOPER: "Developer",
};

const noDataMsg = "No Data";

const txnType = {
  MOBILE_TOP_UP: "Mobile Top Up",
};

const constants = {
  env,
  networkPassphrase:
    env === "production" ? "ssn_production_network" : "ssn_master_network",
  mainColor: "#007AFF",
  greenColor: "#3BAC00",
  redColor: "red",
  videoSizeLimit,
  appSC,
  gqlPrefix: "",
  MEDIA_GROUP,
  MEDIA_TYPE,
  gatewayGqlUrl,
  serviceCode,
  mediaGqlUrl,
  soyonowVideoFolderId,
  elementType,
  shareService,
  resellerGqlUrl,
  VIP_BUNDLE_CATEGORY_TITLE,
  JX2_SERVICE_CODE,
  GAME_BUNDLE_ATTACHED_ITEM_FIELD_NAME,
  mySabayServiceCode,
  VIP_LEVEL_MANDATORY_FIELD_NAME,
  VIP_LEVEL_MANDATORY_OPTIONS,
  VIP_LEVEL_MANDATORY_OPTIONS_FOR_UPDATE,
  Status,
  Types,
  gameTypes,
  adminCashierUrl,
  transferSGDomainName,
  gameFiPlatforms,
  toggleBlockchain,
  metadataType,
  noDataMsg,
  txnType,
};

export default constants;
