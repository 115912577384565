import React from "react";

import { Redirect, Route } from "react-router-dom";
import LoginContainer from "../containers/login/login";
import { getToken } from "../providers/network";

const ProtectedRoute = (props) => {
    const {
        path,
        name,
        ...rest
    } = props;

    const isAuthenticated = async () => {
        const token = await getToken();
        if (token) {
            return true;
        } 
        return false;
    };

    return (
        <>
            <Route
                path={path}
                name={name}
                {...rest}
                render={(props) => isAuthenticated() ? <LoginContainer {...props} /> :
                    <Redirect
                        push
                        to="/login"
                    />}
            />
        </>
    );
};

export default ProtectedRoute;
