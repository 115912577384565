import React from "react";

const Login = React.lazy(() => import("../views/pages/login/login"));
const SecretWords = React.lazy(() =>
  import("../views/pages/login/secretWords")
);
const ConfirmSecretWords = React.lazy(() =>
  import("../views/pages/login/confirmSecretWords")
);
const ConfirmSecretWordsWithKey = React.lazy(() =>
  import("../views/pages/login/confirmSecretWordsWithKey")
);
const Register = React.lazy(() => import("../views/pages/register/register"));
const Page404 = React.lazy(() => import("../views/pages/page404/page404"));
const Page500 = React.lazy(() => import("../views/pages/page500/page500"));

const routes = [
  {
    path: "/login",
    exact: true,
    name: "Login Page",
    title: "Login - Page",
    component: Login,
  },
  {
    path: "/secret-words",
    exact: true,
    name: "Secret Words Page",
    title: "Secret Words Page",
    component: SecretWords,
  },
  {
    path: "/confirm-secret-words",
    exact: true,
    name: "Confirm Secret Words Page",
    title: "Confirm Secret Words Page",
    component: ConfirmSecretWords,
  },
  {
    path: "/confirm-secret-words-with-key",
    exact: true,
    name: "Confirm Secret Words With Key Page",
    title: "Confirm Secret Words With Key Page",
    component: ConfirmSecretWordsWithKey,
  },
  {
    path: "/register",
    exact: true,
    name: "Register Page",
    title: "Register Page",
    component: Register,
  },
  {
    path: "/404",
    exact: true,
    name: "Page 404",
    title: "Page 404",
    component: Page404,
  },
  {
    path: "/500",
    exact: true,
    name: "Page 500",
    title: "Page 500",
    component: Page500,
  },
];

export default routes;
